import { jsx as _jsx } from "react/jsx-runtime";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
const Context = React.createContext({
    stripe: undefined,
    state: "loading",
});
function Component({ publishableKey, children, }) {
    const [stripe, setStripe] = React.useState(undefined);
    const [state, setState] = React.useState("loading");
    React.useEffect(() => {
        setStripe(undefined);
        setState("loading");
        if (!publishableKey)
            return;
        loadStripe(publishableKey)
            .then((stripe) => {
            setStripe(stripe);
            setState("ready");
        })
            .catch((error) => {
            console.error(error);
            setState("error");
        });
    }, [publishableKey]);
    // TODO: Do we want to show a loading state?
    return (_jsx(Context.Provider, Object.assign({ value: { stripe, state } }, { children: children })));
}
export function useStripeContext() {
    const context = React.useContext(Context);
    if (context === undefined) {
        throw new Error('useStripeContext must be a child of "Stripe Context" node.');
    }
    return context;
}
const nodeName = "stripe.context";
export const node = Noodl.defineReactNode({
    name: nodeName,
    displayName: "Stripe Context",
    category: "Visual",
    useVariants: false,
    getReactComponent() {
        return Component;
    },
    inputProps: {
        publishableKey: {
            group: "Configuration",
            type: "string",
            displayName: "Publishable Key",
        },
    },
});
