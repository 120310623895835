import { jsx as _jsx } from "react/jsx-runtime";
// https://github.com/stripe-samples/accept-a-payment/blob/main/payment-element/client/react-cra/src/Payment.jsx
import { Elements } from "@stripe/react-stripe-js";
import { useStripeContext } from "./StripeContext";
function Component({ clientSecret, appearance, children, }) {
    const { stripe } = useStripeContext();
    if (!stripe || !clientSecret) {
        return null;
    }
    return (_jsx(Elements, Object.assign({ stripe: stripe, options: { clientSecret, appearance } }, { children: children })));
}
const nodeName = "stripe.elements";
export const node = Noodl.defineReactNode({
    name: nodeName,
    displayName: "Stripe Elements",
    category: "Visual",
    useVariants: false,
    getReactComponent() {
        return Component;
    },
    inputProps: {
        clientSecret: {
            group: "Configuration",
            type: "string",
            displayName: "Client Secret",
        },
        // https://docs.stripe.com/elements/appearance-api
        appearance: {
            group: "Configuration",
            type: "object",
            displayName: "Appearance",
        },
    },
});
