var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import * as React from "react";
function Component(props) {
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = (e) => __awaiter(this, void 0, void 0, function* () {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        props.outIsLoading(true);
        const { error } = yield stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: props.returnUrl,
            },
        });
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            props.outErrorMessage(error.message);
        }
        else {
            props.outErrorMessage("An unexpected error occured.");
        }
        props.outIsLoading(false);
    });
    React.useEffect(() => {
        props.eventHandler.addEventListener("confirmPayment", handleSubmit);
        return function () {
            props.eventHandler.removeEventListener("confirmPayment", handleSubmit);
        };
    }, [props.eventHandler]);
    return (_jsx("form", Object.assign({ style: { width: "100%", height: "100%" }, onSubmit: handleSubmit }, { children: props.children })));
}
const nodeName = "stripe.form";
export const node = Noodl.defineReactNode({
    name: nodeName,
    displayName: "Stripe Form",
    category: "Visual",
    useVariants: false,
    getReactComponent() {
        return Component;
    },
    initialize() {
        this.props.eventHandler = new EventTarget();
    },
    inputProps: {
        // Make sure to change this to your payment completion page
        returnUrl: {
            group: "Configuration",
            type: "string",
            displayName: "Return Url",
        },
    },
    outputProps: {
        outIsLoading: {
            group: "State",
            type: "boolean",
            displayName: "Is Loading",
        },
        outErrorMessage: {
            group: "State",
            type: "boolean",
            displayName: "Error Message",
        },
    },
    signals: {
        confirmPayment: {
            displayName: "Confirm Payment",
            group: "Actions",
            signal() {
                this.props.eventHandler.dispatchEvent(new Event("confirmPayment"));
            },
        },
    },
});
