import { jsx as _jsx } from "react/jsx-runtime";
import { LinkAuthenticationElement } from "@stripe/react-stripe-js";
function Component(props) {
    return (_jsx(LinkAuthenticationElement, { id: props.id, className: props.className, 
        // Access the email value like so:
        // onChange={(event) => {
        //  setEmail(event.value.email);
        // }}
        //
        // Prefill the email field like so:
        options: { defaultValues: { email: "foo@bar.com" } } }));
}
const nodeName = "stripe.link-authentication-element";
export const node = Noodl.defineReactNode({
    name: nodeName,
    displayName: "Stripe Link Authentication Element",
    category: "Visual",
    useVariants: false,
    getReactComponent() {
        return Component;
    },
    inputProps: {},
});
