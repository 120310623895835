import { jsx as _jsx } from "react/jsx-runtime";
import { PaymentElement } from "@stripe/react-stripe-js";
function Component(props) {
    // TODO: defaultValues
    return (_jsx(PaymentElement, { id: props.id, className: props.className, options: {
            layout: props.layout,
        } }));
}
const nodeName = "stripe.payment-element";
export const node = Noodl.defineReactNode({
    name: nodeName,
    displayName: "Stripe Payment Element",
    category: "Visual",
    useVariants: false,
    getReactComponent() {
        return Component;
    },
    inputProps: {
        id: {
            group: "Configuration",
            type: "object",
            displayName: "Element ID",
        },
        layout: {
            group: "Configuration",
            type: "object",
            displayName: "Layout",
        },
    },
});
