import * as Noodl from "@noodl/noodl-sdk";
import * as StripeElementsNode from "./reactNodes/StripeElements";
import * as StripePaymentElementNode from "./reactNodes/StripePaymentElement";
import * as StripeLinkAuthenticationElementNode from "./reactNodes/StripeLinkAuthenticationElement";
import * as StripeAddressElementNode from "./reactNodes/StripeAddressElement";
import * as StripeContextNode from "./reactNodes/StripeContext";
import * as StripeFormNode from "./reactNodes/StripeForm";
Noodl.defineModule({
    reactNodes: [
        StripeContextNode.node,
        StripeFormNode.node,
        StripeElementsNode.node,
        StripeLinkAuthenticationElementNode.node,
        StripeAddressElementNode.node,
        StripePaymentElementNode.node,
    ],
    nodes: [],
    setup() { },
});
