import { jsx as _jsx } from "react/jsx-runtime";
import { AddressElement } from "@stripe/react-stripe-js";
function Component(props) {
    return (_jsx(AddressElement, { id: props.id, className: props.className, options: {
            mode: "billing",
            fields: {
                phone: "always",
            },
            defaultValues: {},
        } }));
}
const nodeName = "stripe.address-element";
export const node = Noodl.defineReactNode({
    name: nodeName,
    displayName: "Stripe Address Element",
    category: "Visual",
    useVariants: false,
    getReactComponent() {
        return Component;
    },
    inputProps: {},
});
